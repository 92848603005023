import React from 'react';
import Supercluster from 'supercluster';
import { markerMaker, markerUtils } from '~/utils/map';
import { AssignedWebColor } from '~/api/types';
import { MapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { HookOnDemandDispatchMarkerEventHandler } from '~/hooks';
import { EmittedEventHandler } from '~/components/MapPage/PlanMap/types';
import { makeLiveClusterMarkers } from '~/utils/map-modes/live-stops-clusters-mode';
import { idUtils } from '~/utils/id-utils';
import { OnDemandDispatchMarkerEventHandler } from '~/ui/components/LiveStopMarker/types';

import { StackPinMarker } from '~/ui';

interface MakeLiveStopMarkersProps {
    mapRouteMode: MapRouteMode;
    onDemandDispatchMarkerEventHandler: HookOnDemandDispatchMarkerEventHandler;
    driverColor: AssignedWebColor;
    emittedEventHandler: EmittedEventHandler;
    liveStopsSuperClusters: Supercluster.AnyProps[];
    getClusters: (
        superCluster: Supercluster.AnyProps
    ) => Supercluster.AnyProps[];
    isClusteringStops: boolean;
}

export const makeLiveStopMarkers = ({
    mapRouteMode,
    onDemandDispatchMarkerEventHandler,
    driverColor,
    emittedEventHandler,
    liveStopsSuperClusters,
    getClusters,
    isClusteringStops
}: MakeLiveStopMarkersProps): JSX.Element[] => {
    const markers: JSX.Element[] = [];
    for (const superCluster of liveStopsSuperClusters) {
        const geoJSONFeatures = getClusters(superCluster);
        const clusterIndex = liveStopsSuperClusters.indexOf(superCluster);

        if (isClusteringStops) {
            return makeLiveClusterMarkers({
                superCluster,
                geoJSONFeatures,
                superClusterIndex: clusterIndex,
                emittedEventHandler,
                onDemandDispatchMarkerEventHandler,
                driverColor,
                mapRouteMode
            });
        }

        const allLeaves = geoJSONFeatures.flatMap((geoJSONFeature) => {
            const isCluster = geoJSONFeature.properties.cluster;
            return isCluster
                ? superCluster.getLeaves(geoJSONFeature.id, Infinity, 0)
                : [geoJSONFeature];
        });

        const groupedByLocation = markerUtils.groupLeavesByLocation(allLeaves);

        for (const locationKey in groupedByLocation) {
            const tasks = groupedByLocation[locationKey];
            if (tasks.length < 2) {
                const leaf = { properties: groupedByLocation[locationKey][0] };
                const marker = markerMaker.makeLiveStopMarker({
                    leaf,
                    mapRouteMode,
                    onDemandDispatchMarkerEventHandler,
                    emittedEventHandler,
                    driverColor
                });
                markers.push(marker);
            } else {
                const numClustered = tasks.length;
                const { lat, lng } =
                    markerUtils.getLocationCoordinates(locationKey);
                const id = idUtils.getCombinedId(numClustered, locationKey);

                const marker = (
                    <StackPinMarker
                        key={id}
                        numClustered={numClustered}
                        emittedEventHandler={emittedEventHandler}
                        onDemandDispatchMarkerEventHandler={
                            onDemandDispatchMarkerEventHandler as OnDemandDispatchMarkerEventHandler
                        }
                        tasks={tasks}
                        lat={lat}
                        lng={lng}
                        colorCSS={driverColor}
                    />
                );
                markers.push(marker);
            }
        }
    }
    return markers;
};
