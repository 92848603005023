import { DateTime, Duration } from 'luxon';
import { convertDateTimeToUTCISOString } from '~/utils/date-utils';
import { AssignmentStatus } from '~/api/types';
import {
    GetChangeTaskStatusPayloadParams,
    GetAllChangeTaskStatusPayloadParams,
    IsReadyChangeTaskStatusParams,
    GetChangeTaskStatusTimesParams,
    ChangeTaskStatusPayload
} from './types';

const getPayloadAssigned = ({
    id: assignmentId,
    timestamp
}: GetChangeTaskStatusPayloadParams): ChangeTaskStatusPayload => {
    return {
        status: AssignmentStatus.ASSIGNED,
        assignmentId,
        timestamp
    };
};

const getPayloadInProgress = ({
    id: assignmentId,
    timestamp
}: GetChangeTaskStatusPayloadParams): ChangeTaskStatusPayload => {
    return {
        status: AssignmentStatus.IN_PROGRESS,
        assignmentId,
        timestamp
    };
};

const getPayloadCompleted = ({
    id: assignmentId,
    timestamp
}: GetChangeTaskStatusPayloadParams): ChangeTaskStatusPayload => {
    return {
        status: AssignmentStatus.COMPLETED,
        assignmentId,
        timestamp
    };
};

const getPayloadCanceled = ({
    id: assignmentId,
    timestamp
}: GetChangeTaskStatusPayloadParams): ChangeTaskStatusPayload => {
    return {
        status: AssignmentStatus.CANCELED,
        assignmentId,
        timestamp
    };
};

export const getChangeTaskStatusPayload = ({
    id,
    currentStatus,
    revisedStatus,
    revisedArrival,
    revisedCompleted
}: GetAllChangeTaskStatusPayloadParams): ChangeTaskStatusPayload[] => {
    const isFromAssigned = currentStatus === AssignmentStatus.ASSIGNED;
    const isFromInProgress = currentStatus === AssignmentStatus.IN_PROGRESS;
    const isFromAllowedStatus = isFromAssigned || isFromInProgress;

    const isToAssigned = revisedStatus === AssignmentStatus.ASSIGNED;
    const isToCompleted = revisedStatus === AssignmentStatus.COMPLETED;
    const isToCanceled = revisedStatus === AssignmentStatus.CANCELED;

    const isUnarrive = isFromInProgress && isToAssigned;
    if (isUnarrive) {
        const now = DateTime.now();
        const timestamp = convertDateTimeToUTCISOString(now);
        return [getPayloadAssigned({ id, timestamp })];
    }

    const changeArrival = isFromAllowedStatus && revisedArrival;
    const changeCompletedTime =
        isFromAllowedStatus && isToCompleted && revisedCompleted;
    const changeCanceledTime =
        isFromAllowedStatus && isToCanceled && revisedCompleted;

    const payload = [
        changeArrival &&
            getPayloadInProgress({ id, timestamp: revisedArrival }),
        changeCompletedTime &&
            getPayloadCompleted({ id, timestamp: revisedCompleted }),
        changeCanceledTime &&
            getPayloadCanceled({ id, timestamp: revisedCompleted })
    ].filter(Boolean) as ChangeTaskStatusPayload[];

    return payload;
};

const isValidStopTimes = ({
    currentArrival,
    revisedStatus,
    revisedArrival,
    revisedCompleted
}: IsReadyChangeTaskStatusParams) => {
    // when changing to `in-progress`, the `arrival` time must be changed
    const isArrivalChanged = currentArrival !== revisedArrival;
    const isToInProgress = revisedStatus === AssignmentStatus.IN_PROGRESS;
    if (isToInProgress) return isArrivalChanged;

    // when changing both `arrival` and `completed` times
    // enforce a minimum of 1 minute difference
    // + the <input type="time" /> is not capable of displaying seconds
    // + by enforcing 1 minute, the UI and validation will match
    const revisedArrivalDateTime = DateTime.fromISO(revisedArrival);
    const revisedCompletedDateTime = DateTime.fromISO(revisedCompleted);
    const revisedTimesDiff =
        revisedCompletedDateTime.toMillis() - revisedArrivalDateTime.toMillis();
    const isDiffValid = revisedTimesDiff >= 60000;

    return isDiffValid;
};
export const isReadyChangeTaskStatus = ({
    id,
    currentStatus,
    currentArrival,
    revisedStatus,
    revisedArrival,
    revisedCompleted
}: IsReadyChangeTaskStatusParams) => {
    const isStopSelected = Boolean(id);

    // while the stop is `in-transit` to its destination, we cannot change the time
    const isFromAssigned = currentStatus === AssignmentStatus.ASSIGNED;
    const isToAssigned = revisedStatus === AssignmentStatus.ASSIGNED;
    const isStopInTransit = isFromAssigned && isToAssigned;

    if (!isStopSelected || isStopInTransit) return false;

    const isStopTimesChanged = isValidStopTimes({
        currentStatus,
        currentArrival,
        revisedStatus,
        revisedArrival,
        revisedCompleted
    });

    return isStopTimesChanged;
};

export const getChangeTaskStatusTimes = ({
    serviceTime,
    currentArrival,
    currentCompleted,
    revisedArrival,
    revisedCompleted
}: GetChangeTaskStatusTimesParams) => {
    const now = DateTime.now();
    const currentArrivalDateTime = currentArrival
        ? DateTime.fromISO(currentArrival)
        : now;
    const serviceDuration = serviceTime
        ? Duration.fromISO(serviceTime).toObject()
        : { hour: 1 };
    const defaultArrival = convertDateTimeToUTCISOString(now);
    const defaultCompleted = convertDateTimeToUTCISOString(
        currentArrivalDateTime.plus(serviceDuration)
    );

    const changedArrival = revisedArrival || currentArrival || defaultArrival;
    const changedCompleted =
        revisedCompleted || currentCompleted || defaultCompleted;

    return {
        changedArrival,
        changedCompleted
    };
};
