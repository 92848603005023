import { useMemo, useState } from 'react';
import { isUndefined } from 'lodash';
import {
    getChangeTaskStatusPayload,
    isReadyChangeTaskStatus,
    getChangeTaskStatusTimes
} from './utils';
import {
    UseChangeTaskStatusStopDataProps,
    UseChangeTaskStatusStopDataReturn
} from './types';

export const useChangeTaskStatusStopData = ({
    stopData,
    selectedStopId
}: UseChangeTaskStatusStopDataProps): UseChangeTaskStatusStopDataReturn => {
    const taskTypeStopData = useMemo(() => {
        const pickupStopData = stopData.find((liveStop) => liveStop.isPickup);
        const deliveryStopData = stopData.find(
            (liveStop) => liveStop.isDelivery
        );

        return {
            pickup: pickupStopData,
            delivery: deliveryStopData
        };
    }, [stopData]);

    const selectedStopData = useMemo(() => {
        if (stopData.length === 1) return stopData[0];

        return stopData.find((liveStop) => liveStop.id === selectedStopId);
    }, [stopData, selectedStopId]);

    const { status, startServiceAt, completedAt, id, serviceTime } =
        selectedStopData || {};

    const [revisedStatus, setRevisedStatus] = useState(status);
    const [revisedArrival, setRevisedArrival] = useState(startServiceAt);
    const [revisedCompleted, setRevisedCompleted] = useState(completedAt);

    const changedStatus =
        !isUndefined(revisedStatus) && revisedStatus >= 0
            ? revisedStatus
            : status;

    const { changedArrival, changedCompleted } = getChangeTaskStatusTimes({
        serviceTime,
        currentArrival: startServiceAt,
        currentCompleted: completedAt,
        revisedArrival,
        revisedCompleted
    });

    const isChangeTaskStatusReady = isReadyChangeTaskStatus({
        id,
        currentStatus: status,
        currentArrival: startServiceAt,
        currentCompleted: completedAt,
        revisedStatus: changedStatus,
        revisedArrival: changedArrival,
        revisedCompleted: changedCompleted
    });

    const changeTaskStatusPayload =
        isChangeTaskStatusReady && id
            ? getChangeTaskStatusPayload({
                  id,
                  currentStatus: status,
                  revisedStatus: changedStatus,
                  revisedArrival: changedArrival,
                  revisedCompleted: changedCompleted
              })
            : [];

    return {
        isChangeTaskStatusReady,
        changeTaskStatusPayload,
        taskTypeStopData: { ...taskTypeStopData, selected: selectedStopData },
        currentStatus: status,
        revisedStatus: changedStatus,
        setRevisedStatus,
        currentArrival: startServiceAt,
        revisedArrival: changedArrival,
        setRevisedArrival,
        currentCompleted: completedAt,
        revisedCompleted: changedCompleted,
        setRevisedCompleted
    };
};
