import { Task } from '~/data-classes';

const checkIfCoordinatesMatch = (a, b) => {
    return a.lat === b.lat && a.lng === b.lng;
};

const isDepotsWithTheSameCoordinates = (firstStop, secondStop) => {
    return checkIfCoordinatesMatch(
        firstStop.markerCoordinates || firstStop.location,
        secondStop.markerCoordinates || secondStop.location
    );
};

const checkIsDepotUnique = (depots, stop) => {
    return !depots.some((depot) => isDepotsWithTheSameCoordinates(depot, stop));
};

// https://tools.ietf.org/html/rfc7946#section-3.2
const getGeoJSONFeaturePoint = (lat, lng, properties = {}) => {
    return {
        type: 'Feature',
        properties,
        geometry: {
            type: 'Point',
            coordinates: [lng, lat]
        }
    };
};

/**
 * Groups Supercluster leaves by coordinates (lat, lng)
 * @param leaves array of supercluster leaves
 * @returns {*} {locationKey: [leaf.properties]}
 */
const groupLeavesByLocation = (leaves) => {
    return leaves.reduce((aggregator, leaf) => {
        const locationKey = leaf.geometry.coordinates.join('_');
        if (aggregator[locationKey]?.length) {
            aggregator[locationKey].push(leaf.properties);
        } else {
            aggregator[locationKey] = [leaf.properties];
        }
        return aggregator;
    }, {});
};

/**
 * Parses lat and lng from location key from leaves grouped by location coordinates
 * @param locationKey
 * @returns {{lng: number, lat: number}}
 */
const getLocationCoordinates = (locationKey) => {
    const [lng, lat] = locationKey.split('_').map((point) => Number(point));
    return { lng, lat };
};

/**
 *  Retuns task id for the purpose of map selection i.e. `selectedMapStopsSlice`
 * @param taskData ApiTask
 * @returns string
 */
const getTaskIdForSelection = (taskData) => {
    const task = new Task(taskData);
    const { isPickup, pickupStopData, deliveryStopData } = task;
    return isPickup
        ? pickupStopData.clientRouteTaskId
        : deliveryStopData.clientRouteTaskId;
};

export const markerUtils = {
    checkIfCoordinatesMatch,
    checkIsDepotUnique,
    getGeoJSONFeaturePoint,
    isDepotsWithTheSameCoordinates,
    groupLeavesByLocation,
    getLocationCoordinates,
    getTaskIdForSelection
};
