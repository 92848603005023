import { DateTime, Duration } from 'luxon';
import {
    Address,
    ApiInventoryItem,
    ApiLiveStop,
    AssignmentDelay,
    AssignmentStatus,
    LiveBreakDetails,
    Coordinates,
    TaskPriority,
    TaskTypes,
    TimeWindow
} from '~/api/types';
import {
    isAssignmentAssigned,
    isAssignmentAtRisk,
    isAssignmentCanceled,
    isAssignmentCompleted,
    isAssignmentEarly,
    isAssignmentInProgress,
    isAssignmentLate,
    isAssignmentOnTime
} from '~/utils/assignment-utils';
import dateUtils from '~/utils/date-utils';

/**
 * LiveStop data class
 *
 * @category Data Classes
 *
 * @example
 * import { LiveStop } from '~/data-classes';
 *
 * const srcData = {};
 * const liveStop = new LiveStop(srcData);
 */
class LiveStop {
    /**
     * The API source data
     */
    private readonly _liveStop: ApiLiveStop;

    /**
     * the `LiveStop` constructor
     * @param {Object} liveStop - the source live stop data
     */
    constructor(liveStop: ApiLiveStop) {
        this._liveStop = liveStop;
    }

    /**
     * the live stop ID
     */
    get id(): string {
        return this._liveStop.id;
    }

    /**
     * the live stop ID
     */
    get driverId(): string | undefined {
        return this._liveStop.driver;
    }

    /**
     * the live stop ID an alias of [driverId]{@link LiveStop#driverId}
     */
    get driver(): string | undefined {
        return this._liveStop.driver;
    }

    /**
     * the live stop name
     */
    get name(): string {
        return this._liveStop.name;
    }

    /**
     * @borrows LiveStop#name as LiveStop#stopName
     */
    get stopName(): string {
        /** aliased to share a common property with PlanStop. */
        return this._liveStop.stopname;
    }

    /**
     * the live stop status code
     */
    get status(): AssignmentStatus {
        return this._liveStop.status;
    }

    /**
     * the live stop delay code
     */
    get delay(): AssignmentDelay {
        return this._liveStop.multiTimeWindowDelay ?? this._liveStop.delay;
    }

    /**
     * whether the live stop has an inventory exception
     */
    get hasInventoryException(): boolean {
        return this._liveStop.hasInventoryException;
    }

    /**
     * the live stop task type
     */
    get type(): TaskTypes {
        return this._liveStop.type;
    }

    /**
     * the live stop external task type
     */
    get externalTaskType(): string | null {
        return this._liveStop?.externalTaskType || null;
    }

    /**
     * whether the live stop is a depot
     */
    get isDepot(): boolean {
        return this._liveStop.isDepot;
    }

    /**
     * whether the live stop is a pickup task
     */
    get isPickup(): boolean {
        return this.type === TaskTypes.PICKUP;
    }

    /**
     * whether the live stop is a delivery task
     */
    get isDelivery(): boolean {
        return this.type === TaskTypes.DELIVERY;
    }

    /**
     * the live stop priority code
     */
    get priority(): TaskPriority {
        return this._liveStop.priority;
    }

    /**
     * whether the live stop is paired with another task (two-part task)
     * @type {boolean}
     */
    get isTwoPart() {
        return this._liveStop.isPaired;
    }

    /**
     * the live stop two-part task id
     */
    get twoPartTaskId() {
        if (!this.isTwoPart) return null;
        return this.taskId;
    }

    /**
     * whether the live stop is a high-priority task
     */
    get isHighPriority(): boolean {
        return this.priority === TaskPriority.HIGH;
    }

    /**
     * the live stop estimated arrival time
     */
    get arrivalTime(): string {
        return this._liveStop.arrivalTime;
    }

    /**
     * the live stop estimated service time expressed as ISO duration string
     */
    get serviceTime(): string {
        return this._liveStop.serviceTime;
    }

    /**
     * whether the live stop service time is estimated by machine learning (ML)?
     */
    get isServiceTimeML(): boolean {
        return this._liveStop.serviceTimeML;
    }

    /**
     * the live stop service time window
     */
    get timeWindow(): TimeWindow[] {
        return this._liveStop.timeWindow;
    }

    /**
     * the live stop service start time, expressed as ISO string
     */
    get startServiceAt(): string {
        return this._liveStop.startServiceAt;
    }

    /**
     * the live stop completed time, expressed as ISO string
     */
    get completedAt(): string {
        return this._liveStop.completedAt;
    }

    /**
     * the live stop actual service duration, expressed in milliseconds
     */
    get serviceDuration(): number | null {
        const completedAt = DateTime.fromISO(this.completedAt);
        const startServiceAt = DateTime.fromISO(this.startServiceAt);

        if (!completedAt.isValid || !startServiceAt.isValid) return null;

        return completedAt.toMillis() - startServiceAt.toMillis();
    }

    /**
     * the live stop service window
     */
    get serviceWindow(): { start: string; end: string } | null {
        const shiftTime = dateUtils.getShiftTime(
            this.arrivalTime,
            this.serviceTime
        );

        if (!shiftTime) return null;

        const { start, end } = shiftTime;
        return { start, end };
    }

    /**
     * the live stop address object
     */
    get address(): Address {
        const { addressLine1, addressLine2, city, state, zipcode, id } =
            this._liveStop.location;

        return {
            addressLine1,
            addressLine2,
            city,
            state,
            zipcode,
            id
        };
    }

    /**
     * the live stop location object
     */
    get location(): Coordinates {
        return this._liveStop.location.location;
    }

    /**
     * the live stop labels
     */
    get labels(): string[] {
        return this._liveStop.labels ?? [];
    }

    /**
     * whether the live stop arrival is early
     */
    get isEarly(): boolean {
        return isAssignmentEarly(this.delay);
    }

    /**
     * whether the live stop arrival is on-time
     */
    get isOnTime(): boolean {
        return isAssignmentOnTime(this.delay);
    }

    /**
     * whether the live stop arrival is at-risk
     */
    get isAtRisk(): boolean {
        return isAssignmentAtRisk(this.delay);
    }

    /**
     * whether the live stop arrival is late
     */
    get isLate(): boolean {
        return isAssignmentLate(this.delay);
    }

    /**
     * whether the live stop is completed
     */
    get isCompleted(): boolean {
        return this._liveStop.isCompleted || isAssignmentCompleted(this.status);
    }

    /**
     * whether the live stop is assigned a driver
     */
    get isAssigned(): boolean {
        return isAssignmentAssigned(this.status);
    }

    /**
     * whether the live stop is in-progress
     */
    get isInProgress(): boolean {
        return isAssignmentInProgress(this.status);
    }

    /**
     * whether the live stop is cancelled
     */
    get isCanceled(): boolean {
        return isAssignmentCanceled(this.status);
    }

    /**
     * the live stop task Id
     */
    get taskId(): string {
        return this._liveStop.task;
    }

    /**
     * the live stop task Id
     */
    get equipmentId(): string {
        return this._liveStop?.equipmentId || '';
    }

    /**
     * whether the live stop is the current stop for this route
     */
    get isCurrentStop(): boolean {
        return this._liveStop.isCurrentStop || false;
    }

    /**
     * the live stop inventory list
     */
    get inventory(): ApiInventoryItem[] {
        return this._liveStop.inventory;
    }

    /**
     * whether the live stop has an inventory list
     */
    get hasInventory(): boolean {
        return this.inventory.length > 0;
    }

    /**
     * the live stop number of inventory items
     * @type {number}
     */
    get numInventoryItems() {
        return this.inventory.length;
    }

    /**
     * whether there is violation in the service time window
     * this is the difference between the serviceDuration and serviceTime
     */
    get isServiceTimeViolated(): boolean {
        const serviceTimeInMilliseconds = Duration.fromISO(
            this.serviceTime
        ).shiftTo('milliseconds').milliseconds;

        return (
            this.serviceDuration !== null &&
            this.serviceDuration > serviceTimeInMilliseconds
        );
    }

    /**
     * the live stop vehicle size (volume) capacity used
     */
    get size(): number {
        return this._liveStop.size;
    }

    /**
     * the live stop vehicle size (volume) capacity used
     */
    get volumeCapacityUsed(): number {
        return this.size;
    }

    /**
     * the live stop vehicle weight capacity used
     */
    get weight(): number {
        return this._liveStop.weight;
    }

    /**
     * the live stop vehicle weight capacity used
     */
    get weightUsed(): number {
        return this.weight;
    }

    /**
     * the break details of a break before this live stop
     */
    get breakDetails(): LiveBreakDetails | undefined {
        return this._liveStop.breakDetails;
    }

    /**
     * the estimated time of arrival
     * @type {String}
     */
    get eta() {
        return this._liveStop.eta;
    }

    /**
     * the live stop service time window, expressed as ISO string
     */
    get rawTimeWindow(): TimeWindow[] {
        return this._liveStop.rawTimeWindow;
    }

    /**
     * the estimated time of arrival, expressed as ISO string
     * @type {String}
     */
    get rawEta() {
        return this._liveStop.rawEta;
    }

    /**
     * route id provided by client
     * @type {String}
     */
    get euid() {
        return this._liveStop.euid;
    }

    /**
     * the live stop route id an alias of [euid]{@link LiveStop#euid}
     * @type {String}
     */
    get routeId() {
        return this.euid;
    }

    /**
     * the live stop sequence number.
     * @type {number}
     */
    get stopNumber(): number {
        return this._liveStop.stopNumber;
    }

    /** aliased to share a common property with PlanStop. */
    get stopId(): string {
        return this.taskId;
    }

    /**
     * whether the live stop is marked as last stop in shift (end of shift)
     */
    get isLastStopInShift() {
        return Boolean(this._liveStop.lastStopInShift);
    }

    /**
     * Returns the live stop sequence number
     * It is indicative of the overall sequence in case of reloads
     * @type {number}
     */
    get driverStopNumber() {
        return this._liveStop.driverStopNumber;
    }

    /**
     * the position of the stop
     * @type {number|undefined}
     */
    get stopPosition(): number | undefined {
        return this._liveStop.stopPosition;
    }

    /**
     * whether there are any completed tasks
     * @type {boolean|undefined}
     */
    get hasCompletedTasks(): boolean | undefined {
        return this._liveStop.hasCompletedTasks;
    }

    /**
     * Returns the subRouteId
     * @type {string | undefined}
     */
    get subRouteId() {
        return this._liveStop.subRouteId;
    }

    /**
     * Serializes this class back to JSON
     */
    toJSON(): ApiLiveStop {
        return this._liveStop;
    }
}

export function isLiveStop(obj: object): obj is LiveStop {
    return Object.hasOwn(obj, '_liveStop');
}

export default LiveStop;
