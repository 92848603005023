import React from 'react';
import {
    DragMarker,
    RouteMarker,
    StopMarker,
    LiveStopMarker,
    EquipmentMarker,
    StackPinMarker
} from '~/ui';
import { idUtils } from '~/utils/id-utils';
import constants from '~/utils/constants';
import { LiveStop, Task } from '~/data-classes';
import { taskDataFactory } from '~/utils/data-factory';
import { getRouteSequenceNumber } from '~/utils/stopUtils';
import { mapPlanStopUtils } from '~/utils/map/map-planstop-utils';
import { StopMarkerNameAndLabels } from '~/ui/components/StopMarker/StopMarkerNameAndLabels';
import { markerUtils } from '~/utils/map/marker-utils';
import { unplannedCSS } from '~/utils/styleContants';

/** @typedef {import('~/data-classes/task').TaskDerivedStopData} TaskDerivedStopData */

const unassignedTasksClusterCSS = {
    ...unplannedCSS,
    opacity: 1
};

const unplannedStopNumber = '?';

function makeLiveStopMarker({
    leaf,
    mapRouteMode,
    onDemandDispatchMarkerEventHandler,
    emittedEventHandler,
    driverColor
}) {
    const liveStop = new LiveStop(leaf.properties);
    const { id, location, isHighPriority, isCanceled } = liveStop;

    let liveStopStatus = constants.liveStopMarkerStatus.default;

    if (isHighPriority) {
        liveStopStatus = constants.liveStopMarkerStatus.priority;
    }

    if (isCanceled) {
        liveStopStatus = constants.liveStopMarkerStatus.canceled;
    }

    return (
        <LiveStopMarker
            sequenceNumber={getRouteSequenceNumber(liveStop)}
            stopData={liveStop}
            status={liveStopStatus}
            mapRouteMode={mapRouteMode}
            key={id}
            lat={location.lat}
            lng={location.lng}
            onDemandDispatchMarkerEventHandler={
                onDemandDispatchMarkerEventHandler
            }
            emittedEventHandler={emittedEventHandler}
            colorCSS={driverColor}
        />
    );
}

function makeStopMarker(geoJSONFeature, emittedEventHandler, t) {
    const planStop = geoJSONFeature.properties;
    const {
        isPlanned,
        isTwoPart,
        stopName,
        markerCoordinates,
        clientRouteTaskId,
        type,
        taskId,
        labels
    } = planStop;

    let key = clientRouteTaskId;
    let sequenceNumber = getRouteSequenceNumber(planStop);
    let { colorCSS } = planStop;
    if (!isPlanned) {
        colorCSS = unplannedCSS;
        sequenceNumber = planStop.isPickup
            ? t('common:unassignedTaskIcons.pickup')
            : t('common:unassignedTaskIcons.delivery');
    }
    if (!isPlanned && isTwoPart) {
        key = idUtils.getCombinedId(type, taskId);
    }
    return (
        <StopMarker
            key={key}
            lat={markerCoordinates.lat}
            lng={markerCoordinates.lng}
            number={sequenceNumber}
            customLabel={
                <StopMarkerNameAndLabels name={stopName} labels={labels} />
            }
            emittedEventHandler={emittedEventHandler}
            colorCSS={colorCSS}
            isPlanned={isPlanned}
            data={planStop}
        />
    );
}

const makeUnassignedTaskMarkers = (task, emittedEventHandler) => {
    const currentTask = new Task(task);
    const { isPickup, pickupTask, deliveryTask, isTwoPart } = currentTask;

    const markers = [];
    if (!isTwoPart) {
        const stopDataTask = taskDataFactory.makeOnDemandDispatchStop(
            isPickup ? pickupTask : deliveryTask
        );
        const marker = makeUnassignedStopMarker(
            emittedEventHandler,
            stopDataTask
        );
        markers.push(marker);
    } else {
        mapPlanStopUtils
            .mapTwoPartTaskToOnDemandDispatchStops(task)
            .forEach((stopDataTask) => {
                markers.push(
                    makeUnassignedStopMarker(emittedEventHandler, stopDataTask)
                );
            });
    }
    return markers;
};

function makeUnassignedStopMarkers(unassignedPlanTasks, emittedEventHandler) {
    const markers = [];
    const groupedByLocation = unassignedPlanTasks.reduce(
        (aggregator, taskData) => {
            const unassignedTask = new Task(taskData);
            const { isPickup, pickupLocationLatLng, deliveryLocationLatLng } =
                unassignedTask;
            const locationKey = isPickup
                ? `${pickupLocationLatLng.lng}_${pickupLocationLatLng.lat}`
                : `${deliveryLocationLatLng.lng}_${deliveryLocationLatLng.lat}`;
            if (!aggregator[locationKey]) {
                aggregator[locationKey] = [];
            }
            aggregator[locationKey].push(taskData);
            return aggregator;
        },
        {}
    );

    for (const locationKey in groupedByLocation) {
        const tasks = groupedByLocation[locationKey];
        if (tasks.length < 2) {
            const task = tasks[0];
            const taskMarkers = makeUnassignedTaskMarkers(
                task,
                emittedEventHandler
            );
            markers.push(...taskMarkers);
        } else {
            const numClustered = tasks.length;
            const { lat, lng } =
                markerUtils.getLocationCoordinates(locationKey);
            const id = idUtils.getCombinedId(numClustered, locationKey);

            const marker = (
                <StackPinMarker
                    key={id}
                    numClustered={numClustered}
                    emittedEventHandler={emittedEventHandler}
                    tasks={tasks}
                    clusterId={id}
                    lat={lat}
                    lng={lng}
                    colorCSS={unplannedCSS}
                />
            );
            markers.push(marker);
        }
    }
    return markers;
}

/**
 * @param {TaskDerivedStopData} stopData
 * @param {Function} emittedEventHandler
 * @returns {StopMarker}
 */
function makeUnassignedStopMarker(emittedEventHandler, stopData) {
    const {
        clientRouteTaskId,
        markerCoordinates,
        stopName,
        isPlanned,
        labels
    } = stopData;

    return (
        <StopMarker
            key={clientRouteTaskId}
            lat={markerCoordinates?.lat}
            lng={markerCoordinates?.lng}
            number={unplannedStopNumber}
            customLabel={
                <StopMarkerNameAndLabels name={stopName} labels={labels} />
            }
            colorCSS={unplannedCSS}
            isPlanned={isPlanned}
            data={stopData}
            emittedEventHandler={emittedEventHandler}
        />
    );
}

/**
 * Creates a StopMarker for use in on demand dispatch.
 * @param {TaskDerivedStopData} stopData
 * @param {Function} emittedEventHandler
 * @returns {StopMarker}
 */
function makeOnDemandDispatchStopMarker(stopData, emittedEventHandler) {
    return (
        <StopMarker
            key={stopData.clientRouteTaskId}
            lat={stopData.lat}
            lng={stopData.lng}
            number={unplannedStopNumber}
            label={stopData.label}
            colorCSS={unplannedCSS}
            isPlanned={stopData.isPlanned}
            isHighPriority={stopData.isHighPriority}
            data={stopData}
            mapRouteMode={constants.mapRouteModes.DISPATCHED}
            emittedEventHandler={emittedEventHandler}
        />
    );
}

function makeClusterMarker({
    superCluster,
    geoJSONFeature,
    superClusterIndex,
    emittedEventHandler,
    onDemandDispatchMarkerEventHandler,
    driverColor
}) {
    const clusterId = geoJSONFeature.properties.cluster_id;
    const leaf = superCluster.getLeaves(geoJSONFeature.id, 1, 0)[0];

    const allTasksInCluster = superCluster
        .getLeaves(geoJSONFeature.id, Infinity, 0)
        .map(({ properties }) => properties);

    const numClustered = geoJSONFeature.properties.point_count;
    const id = idUtils.getCombinedId(superClusterIndex, clusterId);
    const planStop = leaf.properties;
    const [lng, lat] = leaf.geometry.coordinates;
    let { colorCSS } = planStop;
    if (!planStop.isPlanned) {
        colorCSS = driverColor || unplannedCSS;
    }

    return (
        <StackPinMarker
            key={id}
            numClustered={numClustered}
            colorCSS={colorCSS}
            emittedEventHandler={emittedEventHandler}
            onDemandDispatchMarkerEventHandler={
                onDemandDispatchMarkerEventHandler
            }
            tasks={allTasksInCluster}
            clusterId={clusterId}
            lat={lat}
            lng={lng}
        />
    );
}

function makeUnassignedClusterMarker({
    superCluster,
    geoJSONFeature,
    superClusterIndex,
    emittedEventHandler
}) {
    const clusterId = geoJSONFeature.properties.cluster_id;

    const allLeavesInCluster = superCluster.getLeaves(
        geoJSONFeature.id,
        Infinity
    );
    const allTasksInCluster = allLeavesInCluster.map(
        ({ properties }) => properties
    );

    const leaf = superCluster.getLeaves(geoJSONFeature.id, 1, 0)[0];
    const numClustered = geoJSONFeature.properties.point_count;
    const id = idUtils.getCombinedId(superClusterIndex, clusterId);
    const [lng, lat] = leaf.geometry.coordinates;

    return (
        <StackPinMarker
            key={id}
            numClustered={numClustered}
            colorCSS={unassignedTasksClusterCSS}
            emittedEventHandler={emittedEventHandler}
            tasks={allTasksInCluster}
            clusterId={clusterId}
            lat={lat}
            lng={lng}
            isShowClickAllButton
        />
    );
}

function makeRouteMarker(routeLevelData, emittedEventHandler) {
    const { clientRouteId, markerCoordinates, colorCSS } = routeLevelData;

    return (
        <RouteMarker
            key={clientRouteId}
            routeLevelData={routeLevelData}
            emittedEventHandler={emittedEventHandler}
            lat={markerCoordinates.lat}
            lng={markerCoordinates.lng}
            colorCSS={colorCSS}
            selectable
        />
    );
}

function makeDragMarker(location, content) {
    return (
        <DragMarker lat={location.lat} lng={location.lng}>
            {content}
        </DragMarker>
    );
}

function makeEquipmentMarker({ equipment, index }) {
    const { id, lat, lng } = equipment;

    return (
        <EquipmentMarker
            data-testid="equipment-marker"
            key={id ?? index}
            lat={lat}
            lng={lng}
            markerLabel={id}
            showMarkerLabel
        />
    );
}

function makeEquipmentClusterMarker({
    superCluster,
    geoJSONFeature,
    superClusterIndex
}) {
    const clusterId = geoJSONFeature.properties.cluster_id;

    const allLeavesInCluster = superCluster.getLeaves(
        geoJSONFeature.id,
        Infinity
    );
    const allEquipmentInCluster = allLeavesInCluster.map(
        ({ properties }) => properties
    );

    const leaf = superCluster.getLeaves(geoJSONFeature.id, 1, 0)[0];
    const numClustered = geoJSONFeature.properties.point_count;
    const id = idUtils.getCombinedId(superClusterIndex, clusterId);
    const [lng, lat] = leaf.geometry.coordinates;

    return (
        <EquipmentMarker
            data-testid="equipment-cluster-marker"
            key={id ?? index}
            lat={lat}
            lng={lng}
            markerLabel={id}
            allEquipmentInCluster={allEquipmentInCluster}
            numClustered={numClustered}
            isCluster
            clusterId={clusterId}
        />
    );
}

export const markerMaker = {
    makeLiveStopMarker,
    makeStopMarker,
    makeOnDemandDispatchStopMarker,
    makeClusterMarker,
    makeRouteMarker,
    makeDragMarker,
    makeUnassignedStopMarkers,
    makeEquipmentMarker,
    makeUnassignedClusterMarker,
    makeUnassignedStopMarker,
    makeEquipmentClusterMarker
};
